import styled from "styled-components"
import { displays } from "theme/base/Variables"
import { respondTo } from "theme/base/Mixins"

const BackBottonStyled = styled.section`
  ${displays.flexBetween};
  width: 100%;
  padding: 0.5rem;
  background: ${({ theme }) => theme.palette.grey10};

  &.manifest {
    flex-direction: column;
  }

  &.manifest .icon-go-to-back {
    width: 100%;
  }

  &.manifest > div:last-child {
    width: 100%;
    margin-top: 20px;
  }

  & .icon-go-to-back {
    color: ${({ theme }) => theme.palette.grey60};
    font-size: 2rem;
    cursor: pointer;
  }
  
  ${respondTo.sm`
    &.manifest {
      ${displays.flexEvenly};
      flex-direction: initial;
    }

    &.manifest .icon-go-to-back {
      width: initial;
    }

    &.manifest > div:last-child {
      width: initial;
      margin-top: initial;
    }
  `}
`

export default BackBottonStyled
