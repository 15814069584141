import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IconSearch, IconClose } from "theme/common/Icon.styled"
import { ISearchSource } from "interfaces/ISearchSource";
import styles from 'components/Manifest/SearchInput.module.css';

interface SearchInputProps {
  items: ISearchSource[];
  onSelect: (item: any | undefined) => void;
  selectedItem?: any;
}

interface FormValues {
  search: string;
}

const SearchInput = ({ items, onSelect, selectedItem }: SearchInputProps) => {
  const { register, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      search: selectedItem?.name || '',
    },
  });
  
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchValue = watch('search');
  const [isOpen, setIsOpen] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState<Location[]>([]);

  useEffect(() => {
    if (selectedItem) {
      setValue('search', selectedItem.name);
    }
  }, [selectedItem, setValue]);

  useEffect(() => {
    const filtered:any = items.filter(item => 
      item?.id?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setFilteredItems(filtered);
    setIsOpen(searchValue?.length > 0 && filtered?.length > 0 && !selectedItem);
  }, [searchValue, items, selectedItem]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (item: ISearchSource) => {
    setValue('search', item?.name);
    onSelect(item);
    setIsOpen(false);
  };

  const handleClear = () => {
    setValue('search', '');
    onSelect(undefined);
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('search', e.target.value);
    if (selectedItem) {
      onSelect(undefined);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputWrapper}>
        <input
          {...register('search', {
            onChange: handleInputChange
          })}
          type="text"
          className={`${styles.input} ${selectedItem ? styles.selected : ''}`}
          placeholder="Buscar Tienda"
          autoComplete="off"
        />
        <IconSearch className={styles.searchIcon} />
        {selectedItem && (
          <button
            type="button"
            onClick={handleClear}
            className={styles.clearButton}
          >
            <IconClose className="h-4 w-4" />
          </button>
        )}
      </div>

      {isOpen && filteredItems?.length > 0 && (
        <div ref={dropdownRef} className={styles.dropdown}>
          {filteredItems.map((item:any) => (
            <button
              key={item?.id}
              type="button"
              onClick={() => handleSelect(item)}
              className={styles.dropdownItem}
            >
              <div className={styles.itemName}>{item?.name}</div>
              <div className={styles.itemId}>ID: {item?.id}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchInput