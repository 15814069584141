import styled from "styled-components"

export const ContentStyledManifest = styled.div`
  .box-table {
    margin-bottom: 1.5rem;
    
    & > table {
      width: 100%;
      margin-top: 20px;

      & > thead > tr > th {
        color: #000000;
        padding: 0.3rem;
        background: #E5E7ED;
        text-align: center;
      }

      & > tbody > tr {
        border-bottom: #E5E7ED solid 2px;

        & > td {
          color: #000000;
          padding: 0.5rem;
          text-align: center;
          font-weight: normal;

          &.button-actions {
            display: flex;
            justify-content: center;

            & > button {
              margin: 0 2px;
            }
          }
        }
      }
    }

    .link-download {
      cursor: pointer;
      background: #451287;
      border-radius: 25em;
      color: #fff;
      margin: 0 0.25em;
      padding: 0.25em 1em;
      transition: all 500ms ease-out;
      -webkit-transition: all 500ms ease-out;

      &:hover:not(:focus) {
        background: #8C004C;
      }
    }
  }
    
  .box-message {
    margin-top: 20px;
  }
    
  .box-loading {
    margin-top: 20px;
    position: relative;
  }

  .button-file {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    border-radius: 8px;
    border: 0;
    height: 44px;
    transition: all 500ms ease-out;
    color: white;
    padding: 0.5rem 1rem;
    position: relative;
    font-size: 2rem;
    font-weight: 700;
    background: #451287;

    &:hover:not(:focus) {
      background: #8C004C;
    }

    .input-file {
      display: none;
    }
  }


  .loader {
    width: 50px;
    aspect-ratio: 1;
    display:grid;
    -webkit-mask: conic-gradient(from 15deg,#0000,#000);
    animation: l26 1s infinite steps(12);
  }
  .loader,
  .loader:before,
  .loader:after{
    background:
      radial-gradient(closest-side at 50% 12.5%,
      #451287 96%,#0000) 50% 0/20% 80% repeat-y,
      radial-gradient(closest-side at 12.5% 50%,
      #451287 96%,#0000) 0 50%/80% 20% repeat-x;
  }
  .loader:before,
  .loader:after {
    content: "";
    grid-area: 1/1;
    transform: rotate(30deg);
  }
  .loader:after {
    transform: rotate(60deg);
  }

  @keyframes l26 {
    100% {transform:rotate(1turn)}
  }

`

export const ContentFilterSources = styled.div`
  display: flex;
  align-items: center;
  gap: 0 1rem;
`